import React from "react"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import ContentBlock from "../../components/ContentBlock"
import SignUpForm from "../../components/SignUpForm/SignUpForm"

const PageSignUpDriverLocate = () => {
  return (
    <Layout showTrialBanner={false}>
      <PageTitle
        title="90 Day Free Trial for DriverLocate"
        description="Experience the best of digital dispatching, mobile applications, sophisticated billing, and our comprehensive reporting, all with no contract, and no setup fees."
      ></PageTitle>
      <ContentBlock>
        <SignUpForm source="driverlocate" />
      </ContentBlock>
    </Layout>
  )
}

export default PageSignUpDriverLocate
